
.subtitle2{
 font-size: 1.7rem;
 font-family: NexaLight;
 font-weight: 100;
 line-height: 2rem;
 color: #3A3A3A;
 margin-top: 20px;
 margin-bottom: 10px;
}

.subtitle2 label {
    width: 100%;
}